/* global Headway */

// @see https://docs.headwayapp.co/widget for more configuration options.
var config = {
  account: "7q3j4y",
  selector: ".hw-changelog", // CSS selector where to inject the badge
  trigger: ".hw-changelog"
};

document.addEventListener("turbo:load", () => {
  if (document.querySelector(".hw-changelog")) {
    Headway.init(config);
  }
});
