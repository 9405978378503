// on document ready (turbo-style)
document.addEventListener("turbo:load", function () {
  $('.load-later').each(function () {
    $(this).load($(this).data('href'))
  });

  $('.ui.checkbox').checkbox();
  $('select.dropdown').dropdown({ placeholder: false, fullTextSearch: "exact", ignoreDiacritics: true });
  $('div.dropdown').dropdown({ fullTextSearch: "exact" });

  // $('.ui.sidebar').sidebar({
  //     // context: $('.ui.pushable.segment'),
  //     transition: 'overlay', duration: '1.25s',
  // }).sidebar('attach events', 'a.mobile-menu-toggle');

  $('.negative.basic.button').mouseover(function () { $(this).removeClass('basic'); })
    .mouseout(function () { $(this).addClass('basic'); });

  // see websites_controller#search for the server-side implementation
  $.fn.search.settings.templates = {
    website: function (response) {
      if (response.success && response.results) {
        return response.results.map((ws) => {
          return `<a class="result ${ws.classList}">` + ws.name +
            (ws.siteType ? ` (${ws.siteType})` : '') +
            (ws.description ? `<br><small>${ws.description}</small>` : '') + `</a>`;
        }).join("\n");
      }
    },
    message: function (message, type) {
      return `<div class="message message-${type}">${message}</div>`;
    }
  };


  /*
   * jump to website in top nav
   */

  // cmd-k on macos (ctrl-k otherwise) to focus #website-menu
  const isMac = navigator.userAgent.indexOf('Mac') !== -1;

  // if not mac, also change the placeholder to show Ctrl-K instead of Cmd-K
  if (!isMac) {
    document.querySelector('#website-menu input').placeholder = document.querySelector('#website-menu input').placeholder.replace('⌘', 'Ctrl-');
  }

  document.addEventListener('keydown', function (e) {
    const isCmdK = isMac ? e.metaKey && e.key === 'k' : e.ctrlKey && e.key === 'k';

    if (isCmdK) {
      e.preventDefault();
      document.querySelector('#website-menu input').focus();
    }
  });

  $('#website-menu')
    .search({
      type: 'website',
      apiSettings: { url: "/websites/search?q={query}" },
      maxResults: 100, // generally limited by websites#search instead of this
      selectFirstResult: true,
      showNoResults: true,
      onSelect: function (result, response) {
        // $('#website-menu').dropdown('hide results');
        Turbo.visit(result.value);
      },
    });

  let searchInput = document.querySelector("#website-menu input.prompt");
  if (searchInput) {
    searchInput.addEventListener('input', () => {
      let re = /https?:\/\/(?:www\.)?([^\/\s\:]+).*?$/
      if (searchInput.value.match(re)) {
        searchInput.value = searchInput.value.replace(re, '$1')
      }
    });
  }
});
