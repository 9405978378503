document.querySelectorAll('.xload-later').forEach(function (element) {
  element.style.height = element.dataset.expectedHeight + 'px';
});

document.addEventListener('turbo:load', function () {
  document.querySelectorAll('.xload-later').forEach(function (element) {
    fetch(element.dataset.href)
      .then(response => response.text())
      .then(html => {
        const newDiv = document.createElement('div');
        newDiv.innerHTML = html;

        // clear out the existing placeholder content
        element.innerHTML = '';
        element.appendChild(newDiv);
        element.style.height = 'auto';

        const scripts = newDiv.querySelectorAll('script');
        scripts.forEach(script => {
          const newScript = document.createElement('script');
          if (script.src) {
            newScript.src = script.src;
          } else {
            newScript.textContent = script.textContent;
          }
          element.appendChild(newScript);
        });

        // Dispatch a custom event after loading the content
        element.dispatchEvent(new CustomEvent('xload-later:load', { bubbles: true }));
      });
  });
});
