import { create, get } from "@github/webauthn-json";

window.webauthnAuthenticate = function (opts) {
  get({ publicKey: opts }).then((credential) => {
    // console.log(credential);
    // window.credentialDebug = credential;

    if (!credential) {
      return; // failed
    }

    document.getElementById('public_key_credential_json').value = JSON.stringify(credential);
    document.getElementById('webauthn-form').submit();
  });
};

window.webauthnRegister = function (form, opts) {
  create({ publicKey: opts })
    .then((newCredentialInfo) => {
      // console.log(newCredentialInfo)
      document.getElementById('public_key_credential_json').value = JSON.stringify(newCredentialInfo);

      // now that the form is complete, submit it form for real
      form.submit();
    });
};
