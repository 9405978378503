/* global $ */

// multi-word format reminders:
// controller names: use _ in filename and - in html
// target names: use camelCase everywhere

// this one probably didn't have to be done using stimulus BUT good to have a standard way of doing things, I think

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fieldToCompare"];

  connect() {
    this.initialize();
  }

  initialize() {
    // make sure semantic ui dropdowns are initialized
    if (!$('.ui.dropdown select + i.dropdown.icon').length) {
      window.requestAnimationFrame(() => { this.initialize(); });
    } else {
      this.addListeners();
      this.setWarnings();
    }
  }

  disconnect() {
    this.removeListeners();
  }

  addListeners = function () {
    for (let field of this.fieldToCompareTargets) {
      for (let input of field.querySelectorAll('input, select')) {
        $(input).on('change.invoice-form', () => { this.setWarnings(); });
      }
    }
  }

  removeListeners = function () {
    for (let field of this.fieldToCompareTargets) {
      for (let input of field.querySelectorAll('input, select')) {
        $(input).off('change.invoice-form');
      }
    }
  }

  setWarnings = function () {
    // console.log('setWarnings');
    for (let field of this.fieldToCompareTargets) {
      let planValue = field.dataset.planValue;
      field.title = "Plan value: " + planValue;

      let input = field.querySelector('input, select');
      if (input) {
        if (input.value == planValue) {
          field.classList.remove('error', 'warning');
        } else {
          field.classList.add('error', 'warning');
        }
      }

      for (let option of field.querySelectorAll('.dropdown .menu .item')) {
        if (option.dataset.value == planValue) { option.classList.add('default-value'); }
      }
    }
  }
}
