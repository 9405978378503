/* global $ */

// multi-word format reminders:
// controller names: use _ in filename and - in html
// target names: use camelCase everywhere

// this one probably didn't have to be done using stimulus BUT good to have a standard way of doing things, I think

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "startTime" ];

  connect() {
    $(`#${this.startTimeTarget.id} select`).each((index, element) => {
      $(element).on('change', () => {
        $(`#${element.id.replace('start_time', 'end_time')}`).val($(`#${element.id}`).val());
      });
    });
  }
}
