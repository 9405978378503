


// look for save-able forms and attach a cmd-s shortcut to save
document.addEventListener("keydown", (e) => {
  // look for forms with an action and method="post", these are most likely to be saveable
  const formSelector = '.ui.form[action][method="post"]';

  if (document.querySelector(formSelector)) {
    if (e.key == 's' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      document.querySelector(formSelector).submit();
      return false;
    }
  }
});
