// onbeforeunload
var beforeUnloadHandler = (e) => {
  // close menu
  $('.ui.sidebar').sidebar('hide');

  // clear out filter
  $('input[data-target=\'filter.query\']').val('');

  // cancel event to prevent navigation
  // e.preventDefault();
  // return false;
};

addEventListener("beforeunload", beforeUnloadHandler);
addEventListener("turbo:before-visit", beforeUnloadHandler);
