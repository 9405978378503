/***
 * Filter control, aka quick search, to narrow a long list or table down to the ones matching the search query
 *
 * How to implement:
 * 1. Add the filter input:
 *    <div class="ui icon input">
 *      <input placeholder="Filter" type="text" data-target="filter.query" data-action="keyup->filter#execute">
 *      <div class="filter-count" data-target="filter.count"></div>
 *      <i class="search icon" data-target="filter.icon" data-action="click->filter#clearQuery"></i>
 *    </div>
 * 2. Add a wrapper div around the input and the list you want to filter, unless you already have one, and add data-controller="filter" to it
 * 3. Add data-target="filter.item" to each list item or table row you want to show/hide
 * 4. Add data-filter-data-selector="[selector]" to the controller div from step 2 to indicate how to find the searchable text, starting
 *    from the list item or table row (typically "a" or "td:first-child a")
 ***/

import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return ["query", "count", "icon", "item", "checkbox"];
  }

  connect() {
    this.readSettings();
    this.execute();
    // this.executeInverval = setInterval(() => { if (this.itemCountChanged) { this.execute(); } }, 100);
  }

  disconnect() {
    // if (this.executeInverval) clearInterval(this.executeInverval);
  }

  readSettings() {
    let filterQuery = sessionStorage.getItem(`filterQuery-${this.queryTarget.name}`);
    if (filterQuery) this.queryTarget.value = filterQuery;
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = localStorage.getItem(checkbox.id) == "true" || (localStorage.getItem(checkbox.id) == null && checkbox.dataset.checkboxStartsChecked);
    });
  }

  execute() {
    setTimeout(() => {
      let matchedCount = 0;

      this.itemTargets.forEach((item, i) => {
        var matched = true;

        sessionStorage.setItem(`filterQuery-${this.queryTarget.name}`, this.query);

        // query, splitting by whitespace
        if (this.query.length == 0) {
          if (this.iconTarget) {
            this.iconTarget.className = 'search icon';
            this.iconTarget.style.pointerEvents = 'none';
            this.iconTarget.style.cursor = '';
          }
        } else {
          if (this.iconTarget) {
            this.iconTarget.className = 'times icon';
            this.iconTarget.style.pointerEvents = 'all';
            this.iconTarget.style.cursor = 'pointer';
          }

          let queries = this.query.split(/\s+/);
          queries.forEach((query) => {
            if (matched) {
              let invertQuery = (query.substring(0, 1) === "!" || query.substring(0, 1) === "-");
              if (invertQuery) query = query.substring(1);
              const queryRegex = new RegExp(query, "i");

              let queryMatches = false;
              item.querySelectorAll(this.dataSelector).forEach((data) => {
                const text = data.textContent;
                queryMatches = queryMatches || queryRegex.test(text);
              });

              matched = invertQuery ? !queryMatches : queryMatches;
            }
          });
        }

        // checkboxes
        var checkboxGroups = [];
        var checkboxGroupValues = {};
        this.checkboxTargets.forEach((checkbox) => {
          if (checkbox.id) localStorage.setItem(checkbox.id, checkbox.checked.toString());
          if (matched) {
            let invert = checkbox.dataset.checkboxTestInvert === "true";
            let testAttr = checkbox.dataset.checkboxTestAttribute;
            let testVal = checkbox.dataset.checkboxTestValue;

            if (checkbox.dataset.checkboxGroup) {
              var group = checkbox.dataset.checkboxGroup;
              if (checkboxGroupValues[group] == null) checkboxGroupValues[group] = false;
              if (checkbox.checked) {
                // don't pay any attention to the checkbox group unless at least one member is checked
                if (!checkboxGroups.includes(group)) checkboxGroups.push(group);

                checkboxGroupValues[group] = checkboxGroupValues[group] || (invert ? item.dataset[testAttr] != testVal : item.dataset[testAttr] == testVal);
              }
            } else if (checkbox.checked) {
              matched = invert ? item.dataset[testAttr] != testVal : item.dataset[testAttr] == testVal;
            }
          }
        });

        checkboxGroups.forEach(group => {
          if (group)
            matched = matched && checkboxGroupValues[group]
        });

        // all matching logic is done by this point, now apply it
        if (matched) matchedCount++;

        // show/hide items
        item.style.setProperty("display", matched ? "" : "none", "important");
        // also disable all input elements present within unmatched items, so they don't get submitted
        item.querySelectorAll("input").forEach(input => input.disabled = !matched);
      });

      if (!document.querySelector(".load-more-placeholder")) {  // wait until fully loaded
        this.countTarget.innerText = `${matchedCount} / ${this.itemTargets.length}`;
      }
    });
    let event = new Event('filter:execute-complete');
    document.dispatchEvent(event);
  }

  clearQuery() {
    this.queryTarget.value = '';
    this.execute();
    this.queryTarget.focus();
  }

  selectAllVisible(e) {
    // console.log(e.target);
    this.itemTargets.forEach(item => {
      let checkbox = item.querySelector('[data-target="filter.select-item"');
      if (checkbox) checkbox.checked = e.target.checked;
    });
  }

  get dataSelector() {
    return this.data.get("dataSelector");
  }

  get query() {
    return this.queryTarget.value.trim();
  }

  get itemCount() {
    return this.itemTargets.length;
  }

  get itemCountChanged() {
    if (this.previousItemCount !== this.itemCount) {
      // console.log("item count changed from", this.previousItemCount, "to", this.itemCount);
      this.previousItemCount = this.itemCount;
      return true;
    }

    return false;
  }
}
