/* global $, navigator, Rails */

/***
 * Settings (for now, just push notifications)
 *
 * How to implement:
 * 1. ...
 ***/

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "message", "notificationToggle", "notificationDetails", "registrationForm", "registrationEndpoint", "registrationP256dhKey", "registrationAuthKey"];

  connect() {
    this.notificationDetailsTarget.style.display = 'none';

    // detect whether we have serviceWorker support and check current status
    if ('serviceWorker' in navigator) {
      console.log('we have serviceWorker :-)');
      this.hideMessage();
      this.checkSubscriptionStatus();
    } else {
      console.log('we do not have serviceWorker :-(');
      this.displayMessage('no-push-support');
    }
  }

  update() {
    Rails.fire(this.formTarget, 'submit');
  }

  checkSubscriptionStatus() {
    navigator.serviceWorker.ready
      .then((serviceWorkerRegistration) => {
        serviceWorkerRegistration.pushManager.getSubscription()
          .then((subscription) => {
            if (subscription) {
              this.fillInRegistrationInfo(subscription);
              this.notificationToggleTarget.checked = true;
              this.notificationDetailsTarget.style.display = '';
            } else {
              this.notificationToggleTarget.checked = false;
              this.notificationDetailsTarget.style.display = 'none';
              return;
            }
          });
      });
  }

  fillInRegistrationInfo(subscription) {
    const sub = subscription.toJSON();
    this.registrationEndpointTarget.value = sub.endpoint;
    this.registrationP256dhKeyTarget.value = sub.keys.p256dh;
    this.registrationAuthKeyTarget.value = sub.keys.auth;
  }

  toggleNotifications() {
    navigator.serviceWorker.ready
      .then((serviceWorkerRegistration) => {
        if (this.notificationToggleTarget.checked) {
          const pubKeyBytes = this.strToArray(this.fromUrlBase64(window.VAPID_PUBLIC_KEY));
          const options = { userVisibleOnly: true, applicationServerKey: pubKeyBytes };
          serviceWorkerRegistration.pushManager.subscribe(options)
            .then((subscription) => {
              console.log('endpoint:', subscription.endpoint);
              this.fillInRegistrationInfo(subscription);
              this.notificationDetailsTarget.style.display = '';
              return true;
            }).catch((e) => {
              console.log('Error thrown while subscribing to push notifications', e);
              this.displayMessage('notifications-blocked');
              // this.notificationToggleTarget.checked = false;
              return false;
            });
        } else {
          serviceWorkerRegistration.pushManager.getSubscription()
            .then((subscription) => {
              if (!subscription) {
                console.log("Not subscribed, nothing to do.");
                this.notificationDetailsTarget.style.display = 'none';
                return true;
              }
              subscription.unsubscribe()
                .then(() => {
                  console.log("Successfully unsubscribed!.");
                  this.notificationDetailsTarget.style.display = 'none';
                  return true;
                }).catch((e) => {
                  console.log('Error thrown while unsubscribing from push notifications', e);
                  return false;
                });
            });
        }
      });
  }

  displayMessage(msg) {
    var html = msg;

    switch (msg) {
      case 'no-push-support':
        html = 'Sorry, it seems like your browser does not have support for push notifications.'
        break;
      case 'notifications-blocked':
        html = 'It appears you may have chosen to block notifications from this site. Please check your browser settings.'
        break;
    }

    this.messageTarget.innerHTML = html;
    this.messageTarget.style.display = '';
  }

  hideMessage() {
    this.messageTarget.style.display = 'none';
  }

  // get dataSelector() {
  //   return this.data.get("dataSelector");
  // }


  // https://github.com/web-push-libs/vapid/blob/main/js/common.js
  fromUrlBase64(data) {
    /* return a binary array from a URL safe base64 string
    */
    return atob(data
      .replace(/\-/g, "+")
      .replace(/\_/g, "/"));
  }

  // https://github.com/web-push-libs/vapid/blob/main/js/common.js
  strToArray(str) {
    /* convert a string into a ByteArray
     *
     * TextEncoders would be faster, but have a habit of altering
     * byte order
     */
    let split = str.split("");
    let reply = new Uint8Array(split.length);
    for (let i in split) {
      reply[i] = split[i].charCodeAt(0);
    }
    return reply;
  }
}
