import Sortable from 'sortablejs';

window.initSortable = function () {
  if ($('#custom-fields').length > 0) {
    var sortable = new Sortable($('#custom-fields')[0], {
      draggable: 'tr.custom-field',
      handle: 'i.icon',
      store: {
        /**
         * Get the order of elements. Called once during initialization.
         * @param   {Sortable}  sortable
         * @returns {Array}
         */
        get: function (sortable) {
          return [];  // use the original order of elements in html
        },
        
        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: function (sortable) {
          var order = sortable.toArray();
          console.log('set order starting...');
          $.post($('form')[0].action + '/set_sort_order', { order: order, authenticity_token: $('meta[name="csrf-token"]').attr('content') }, function() {
            console.log('set order success');
          });
        }
      }
    });
  }
};
