/* global fetch,URL */

import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    var url = new URL(window.location.href);
    let params = url.searchParams;
    params.append('loadmore', 1);
    params.append('skip', this.data.get('skip'));
    params.append('limit', this.data.get('limit'));

    // console.log(url);
    fetch(url, { method: 'GET', credentials: 'include' })
      .then(response => response.text())
      .then(html => {
        this.element.outerHTML = html;
      }
      );
  }

}
