const copyToClipBoard = (el) => {
  navigator.clipboard.writeText($(el).text() || $(el).val());
  if (el.tagName == 'TEXTAREA' || el.tagName == 'INPUT') {
    el.parentElement.classList.add("click-to-copy-parent", "copied");
    setTimeout(() => el.parentElement.classList.remove("copied"), 500);
  } else {
    el.classList.add("copied");
    setTimeout(() => el.classList.remove("copied"), 500);
  }
};

document.addEventListener("turbo:load", () => {
  document.querySelectorAll(".click-to-copy").forEach((el) => {
    if (el.tagName == 'TEXTAREA' || el.tagName == 'INPUT') {
      el.onfocus = () => setTimeout(() => {
        el.select();
        copyToClipBoard(el);
      });
    } else {
      el.onclick = () => copyToClipBoard(el);
    }
    if (el.title) {
      el.title = el.title + " - Copy content to clipboard";
    } else {
      el.title = "Copy content to clipboard";
    }
  });
});
